import { useHead } from '@unhead/vue';
import type { Graph, Thing, WithContext } from 'schema-dts';
import { computed } from 'vue';

export type JsonLD = WithContext<Thing> | WithContext<Thing>[] | Graph | null;
export type JsonLDFunction = () => JsonLD;

const isFunction = (json: JsonLD | JsonLDFunction): json is JsonLDFunction => typeof json === 'function';

export const useJsonld = (key: string, json: JsonLD | JsonLDFunction) => {
  if (!json) {
    return;
  }

  const jsonComputed = computed(() => (isFunction(json) ? json() : json));

  useHead(() => {
    if (!jsonComputed.value) {
      return {};
    }

    return {
      script: [
        {
          key,
          type: 'application/ld+json',
          // eslint-disable-next-line unicorn/no-null
          children: JSON.stringify(jsonComputed.value, null, ''),
        },
      ],
    };
  });
};
